@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.adWrapper {
	position: relative;
	text-align: center;

	height: 100%;
	width: 100%;
	max-width: 100vw;
}

.adContainer {
	display: flex;
	flex-flow: row nowrap;

	height: 100%;
	align-items: center;

	white-space: pre-wrap;
	padding-bottom: 30px;

	padding-top: 75px;
}

.imageWrapper {
	width: 1036px;
	height: auto;
}

.image {
	width: 590px;
	height: auto;

	object-fit: cover;
	object-position: center;
}

.wrapper {
	display: flex;
	flex-direction: column;

	max-width: 690px;

	text-align: left;
}

.qrCode {
	background-color: white;
	width: 206px;
	height: 206px;
	margin-bottom: 22px;
}

.title {
	font-family: $font-family-trim;
	font-weight: $velo-bold;
	font-size: 56px;
	letter-spacing: 0.001em;
	line-height: 1;
	color: white;

	margin-bottom: 20px;


	[data-locale='za'] & {
		font-size: 50px;
	}

	[data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $trim-black;
		color: #192a53;
	}

	[data-locale='za'] & {
		color: white;
	}

	[data-theme='glo'] & {
		font-family: $font-family-mont;
		font-weight: $mont-heavy;
	}
}

.copy {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	color: white;

	font-size: 24px;
	line-height: 36px;
	letter-spacing: 0;

	[data-theme='velo'] & {
		color: black;
	}

	[data-locale='za'] & {
		font-size: 20px;
		color: white;
	}
}