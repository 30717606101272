@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	position: absolute;

	z-index: 9;

	width: rescale(150px);
	height: rescale(150px);

	top: calc(#{rescale(120px)} / -2);
	left: calc(50% - #{rescale(150px)} / 2);

	[data-locale='us'] & {
		top: calc(#{rescale(147px)} / -2);
	}
}

.white {
	width: 100%;
	height: 100%;

	padding-top: 18px;

	background-color: white;
	border-radius: 50%;
	z-index: 13;

	span,
	div {
		display: block;
		color: #777777;
		text-align: center;
		font-family: $font-family-gotham;
		text-transform: uppercase;

		&.explore {
			font-weight: $gotham-black;
			font-size: rescale(15px);
			letter-spacing: -0.01em;
			line-height: 0.818;

			padding-top: 120px;

			&::before {
				content: '';

				border-style: solid;
				border-width: 0 3px 3px 0;
				display: inline-block;
				padding: 6px;

				position: absolute;
				bottom: 76px;
				left: calc(50% - 5px);

				transform: rotate(-135deg);
			}
		}

		&.flavour {
			font-weight: $gotham-medium;
			font-size: rescale(8px);
			letter-spacing: 0.08em;
			line-height: 2;

			position: relative;
		}
	}
}

[data-locale='uk'],
[data-locale='za'] {
	.wrapper {
		bottom: calc(200px / -2);

		span,
		div {
			color: #242424;
		}
	}
}
