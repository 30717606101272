@import '../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../node_modules/bat-components/dist/styles/includes/functions';

.scrollWrapper {
	position: relative;
	grid-area: content;

	transition: transform 0.6s ease-in-out;
	transition-delay: 0.4s;

	padding-top: 100px;

	z-index: 5;

	[data-locale='us'] & {
		padding-top: 0;
	}

	[data-locale='de'] &,
	[data-locale='uk'] &,
	[data-locale='za'] & {
		padding-top: 0;
	}
}

.interactiveWrapper {
	position: absolute;
	top: 100%;

	width: 100%;
	height: calc(100% - 76px);

	z-index: 4;

	background-color: green;

	transition: transform 0.6s ease-in-out;
	transition-delay: 0.4s;

	[data-locale='us'] & {
		height: 773px;
	}
}

.black {
	position: relative;
	width: 100vw;
	height: 100vh;

	background-color: black;
	z-index: 1000;
}

:root {
	touch-action: pan-x pan-y;
	height: 100%;
}
