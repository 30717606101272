@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.contents {
	position: relative;
	width: 100%;
	min-height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	[data-locale='uk'] & {
		background: #161819;
	}
}
