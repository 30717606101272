@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: 80px;

	position: relative;
	z-index: 5;

	color: white;

	white-space: pre-line;
	text-align: center;

	[data-locale='de'] & {
		padding-top: 180px;
	}

	.title {
		font-family: $font-family-gotham;
		font-weight: $gotham-black;

		font-size: rescale(30px);
		letter-spacing: -0.01em;
		line-height: 1;

		text-transform: uppercase;
	}

	.columns {
		display: flex;
		flex: 1 1 auto;

		flex-flow: row nowrap;
		justify-content: center;
		align-items: flex-end;

		height: 100%;
		padding-bottom: 80.6px;

		.column {
			display: flex;
			flex-direction: column;
			flex: 0 0;
			flex-basis: rescale(340px);

			align-items: center;

			img {
				height: auto;
				width: 100%;

				object-fit: contain;
				object-position: bottom;

				max-width: 100%;
				margin: 0;
			}

			.name {
				padding-top: 20px;

				font-size: rescale(27px);
				letter-spacing: -0.01em;
				color: white;

				.glo {
					font-family: $font-family-itcAvant;
					font-weight: $itcAvant-book;

					& > span {
						font-weight: $itcAvant-demi;
					}
				}

				.vype {
					font-family: $font-family-gotham;
				}

				.dunhill {
					font-family: $font-family-gotham;
				}

				.epok,
				.lyft {
					font-family: $font-family-foundry;
					font-weight: $foundry-normal;
					text-transform: uppercase;
				}

				.velo {
					font-family: $font-family-euclidFlex;
					font-weight: $euclidFlex-light;
					text-transform: uppercase;
				}
			}

			button,
			a {
				margin-top: 30px;
			}
		}
	}
}
