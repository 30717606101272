@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.backButton.customisedToProduct {
	position: absolute;
	bottom: 28px;
	padding: 15px 45px;
	transform: translateX(-50%);
	border: none !important;

	font-size: 20px;

	[data-theme='vuse'] & {
		color: white !important;
		background: linear-gradient(90deg, rgba(241, 176, 44, 1) 0%, rgba(235, 92, 33, 1) 100%);
	}

	[data-theme='velo'] & {
		color: white !important;
		background: linear-gradient(90deg, #00acd5 0%, #006bb8 100%);
	}
	[data-theme='glo'] & {
		background: white;
	}
}

.accesoriesWrapper {
	position: relative;
	text-align: center;

	height: 100%;
	width: 100%;
	max-width: 100vw;
}
