@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.subscriptionWrapper {
	display: flex;
	flex-direction: column;

	height: 100%;
	padding: 160px 220px 0;

	white-space: pre-wrap;
}

.container {
	height: 670px;
	color: white;
	padding-top: 20px;
}

.cta {
	text-align: center;
	font-family: $font-family-gotham;
	font-size: 32px;
	font-weight: $gotham-book;

	letter-spacing: 0;
	line-height: 1.062;
	color: #f4a805;

	animation-name: disappear;
	animation-duration: 0.55s;
	animation-delay: 4.7s;
	animation-fill-mode: forwards;
}

.button {
	position: relative;
	width: 430px;
	height: 70px;
	flex: 0 0 auto;
	align-self: center;

	font-family: $font-family-gotham;
	font-weight: $gotham-bold;
	font-size: 20px;
	text-transform: uppercase;
	color: white;

	border-radius: 40px;
	background: linear-gradient(90deg, rgba(241, 176, 44, 1) 0%, rgba(235, 92, 33, 1) 100%);

	transition: width 0.6s cubic-bezier(0.68, -0.6, 0.3, 1.2);

	opacity: 0;
	animation-name: appear;
	animation-duration: 0.6s;
	animation-fill-mode: forwards;

	&.crossButton {
		width: 70px;
	}
}
.ctaWrapper {
	display: block;
	min-height: 35px;
	margin-top: 60px;
	text-align: center;
}

.smallPrint {
	color: white;
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	text-align: center;
	line-height: 1.3em;
	text-align: center;
	font-size: 15px;

	margin: 10px auto;

	opacity: 0;
	animation-name: appear;
	animation-duration: 0.6s;
	animation-delay: 1600ms;
	animation-fill-mode: forwards;
}

.textContainer {
	position: absolute;
	white-space: nowrap;
	transform: translate(-50%, -50%);
}

.cross {
	width: 15px;
	height: 15px;
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes disappear {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
