@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.contents {
	position: relative;
	width: 100%;
	height: 100%;

	z-index: 5;
	margin: 0 auto;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	[data-locale='za'] & {
		height: unset;
	}

	[data-locale='de'] & {
		padding-top: 100px;
		height: calc(100% - 100px);
	}

	[data-locale='uk'] & {
		max-height: 93%;
	}

}
