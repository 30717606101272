@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.iconsContainer {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;

	max-width: 80%;
	margin: 0 auto;

	[data-theme='velo'] [data-layout='pick'] & {
		max-width: 76%;
	}
}

.iconWrapper {
	margin-top: 60px;
	flex: 0 0 438px;
	display: flex;
	flex-direction: column;

	[data-layout='eliquids'][data-type='steam'] & {
		flex: 0 0 310px;
	}

	[data-theme='vuse'] & {
		flex: 0 0 474px;
	}
	[data-theme='velo'] [data-layout='pick'] & {
		margin-top: 125px;
		flex: 0 0 285px;
	}

	[data-locale='de'] & {
		margin-top: 110px;

		flex: 0 0 272px;
	}

	[data-locale='de'] [data-layout='strenghts'] & {
		margin-top: 115px;
	}
}

.icon {
	height: 210px;
	width: auto;

	margin-bottom: 25px;

	[data-theme='velo'] [data-layout='pick'] & {
		height: 50px;
		margin-bottom: 50px;
	}

	[data-theme='vuse'] & {
		margin-bottom: 35px;
		height: 170px;
	}

	[data-locale='de'] & {
		height: 140px;
	}
	[data-locale='de'] [data-layout='strenghts'] & {
		height: 124px;
	}
}

.title {
	font-size: 36px;
	letter-spacing: 0.02em;
	margin-bottom: 5px;
	line-height: 1;

	[data-theme='velo'] & {
		color: #192a53;
		font-family: $font-family-velo;
		font-weight: $velo-bold;
	}

	[data-locale='za'][data-theme='velo'] & {
		color: white;
	}
	[data-locale='uk'][data-theme='velo'] & {
		color: white;
	}

	[data-theme='vuse'] & {
		font-family: $font-family-gotham;
		font-weight: $gotham-medium;
		font-size: 31px;
	}

	[data-theme='glo'] & {
		font-family: $font-family-mont;
		font-weight: $mont-heavy;
	}

	[data-locale='de'] & {
		font-size: 24px;
		font-weight: $gotham-medium;

		line-height: 1.166;
		letter-spacing: 0.02em;

		margin-bottom: 2px;
	}
}

.copy {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 20px;
	line-height: 1.5;
	letter-spacing: 0;

	margin-top: 5px;

	[data-locale='de'] & {
		font-size: 18px;

		line-height: 1.5;
		letter-spacing: 0.02em;
	}
}

// SPLIT BUTTON

[data-locale='de'] .splitButtonAbout {
	a:last-child {
		border-right: none !important;
	}
}

/* ONE COLUMN STYLES */
.ocLeftColumn {
	width: 885px;

	margin: 96px 0 0 auto;

	text-align: left;
	white-space: pre-wrap;

	[data-layout='risk'] & {
		margin: 110px 0 0 auto;
	}
	[data-layout='eliquids'][data-type='know'] & {
		margin: 130px 0 0 auto;
	}
}

.ocImage {
	height: 60px;
	margin-bottom: 40px;
}

.ocTitle {
	font-family: $font-family-trim;
	font-weight: $trim-black;

	font-size: 56px;
	letter-spacing: 0.01em;
	line-height: 1;
	margin-bottom: 20px;
	color: white;

	[data-layout='eliquids'][data-type='certificate'] & {
		margin-bottom: 40px;
	}

	[data-layout='eliquids'][data-type='know'] &,
	[data-layout='know'] & {
		font-size: 64px;
		line-height: 1.06;
	}

	[data-layout='tingle'] & {
		font-family: $font-family-velo;
		font-weight: $velo-bold;
		font-size: 56px;
		letter-spacing: 0.01em;
		line-height: 1;
		margin-bottom: 20px;
		color: white;
	}
}

.ocBody {
	font-size: 27px;
	letter-spacing: 0;
	line-height: 1.48em;
	color: white;

	max-width: 684px;
	white-space: pre-line;

	&.paragraphWithIcon {
		display: flex;
		flex-direction: row nowrap;
		margin-bottom: 15px;
		font-size: 25px;

		font-weight: $gotham-book;
	}

	.ocBodyIcon {
		height: 42px;
		width: auto;
		margin-right: 20px;
	}

	[data-layout='risk'] & {
		font-size: 23px;
		max-width: 765px;
	}

	[data-layout='eliquids'][data-type='know'] &,
	[data-layout='know'] & {
		font-family: $font-family-gotham;
		font-weight: $gotham-book;

		font-size: 28px;
		max-width: 725px;

		line-height: 1.5em;
	}

	[data-layout='tingle'] & {
		font-family: $font-family-velo;
		font-weight: $velo-regular;
		font-size: 27px;
		letter-spacing: 0;
		line-height: 1.48em;
		color: white;

		max-width: 684px;
		white-space: pre-line;
	}
}

// THREE COLUMNS
.tcContainer {
	display: grid;
	grid-template-columns: 445px 110px 445px;
	column-gap: 360px;
	row-gap: 33px;
	align-items: center;
	justify-content: center;
	margin: 70px auto 0;
}

.cell {
	display: flex;
	flex-direction: column;

	font-family: $font-family-gotham;
	font-weight: $gotham-medium;
	color: white;

	font-size: 25px;
	letter-spacing: 0;
	line-height: 1.44;

	.cellImage {
		flex: 0 0 auto;
		height: 87px;
		width: 110px;
		object-fit: contain;
		margin-bottom: 7px;
	}

	&.cell-0 {
		text-align: right;
	}

	&.cell-1 {
		align-items: center;
		font-weight: $gotham-bold;
		font-size: 22px;
	}

	&.cell-2 {
		text-align: left;
	}
}
