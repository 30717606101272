@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.productsInfo {
	position: relative;
	flex: 0 0;
	flex-basis: rescale(525px);

	padding: rescale(60px) rescale(45px) rescale(18.6px) rescale(100px);

	text-align: left;

	white-space: pre-line;

	[data-locale='za'][data-theme='glo'] & {
		flex-basis: 856px;
		padding: 180px rescale(45px) rescale(18.6px) rescale(100px);
	}

	[data-locale='za'][data-theme='velo'] & {
		flex-basis: 790px;
		padding: 183px 110px 0;
	}

	&.noVideos {
		padding: rescale(90px) 0px rescale(18.6px) rescale(90px);
	}

	&.lyft {
		padding: rescale(60px) 0px rescale(18.6px) rescale(90px);
	}

	.logo {
		width: rescale(110px);
		height: auto;

		[data-locale='uk'] & {
			width: auto;
			height: 144px;
		}

		[data-locale='za'] & {
			width: auto;
			height: 125px;
		}

		[data-locale='de'][data-theme='dunhill'] & {
			width: auto;
			height: 125px;
		}

		[data-locale='za'][data-theme='glo'] & {
			width: auto;
			height: 97px;
		}
	}

	.copy {
		[data-locale='za'][data-theme='glo'] & {
			font-size: 25px;
		}

		[data-locale='za'][data-theme='velo'] & {
			font-size: 25px;
			margin-bottom: 60px;
		}

		[data-locale='uk'][data-theme='velo'] & {
			font-size: 25px;
		}
	}

	[data-locale='uk'][data-theme='vuse'] & {
		flex-basis: 745px;
	}

	[data-locale='uk'][data-theme='velo'] & {
		flex-basis: 780px;
		margin-top: 100px;
		padding-left: 125px;
	}

	[data-locale='za'][data-theme='vuse'] & {
		margin-top: 94px;
	}

	[data-locale='uk'][data-theme='vuse'] & {
		margin-top: 90px;
	}

	[data-locale='de'][data-theme='velo'] & {
		padding-top: 30px;
	}

	.videoContainer {
		margin-right: 20px;

		[data-locale='us'] & {
			margin-right: 0;
		}
	}

	.videoText {
		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		color: white;
		font-size: rescale(12px);
		letter-spacing: -0.022em;

		margin-bottom: 7.3px;

		[data-locale='us'] & {
			font-weight: $gotham-bold;
		}

		[data-locale='za'][data-theme='glo'] & {
			text-transform: none;
		}

		[data-locale='za'][data-theme='velo'] & {
			color: black;
		}

		[data-theme='velo'][data-locale='za'] & {
			color: white;
			letter-spacing: 0;
		}

		[data-theme='velo'][data-locale='uk'] & {
			color: white;
		}

		&.velo,
		&.lyft,
		&.epok {
			color: black;

			[data-locale='za'] & {
				color: white;
			}

			[data-locale='uk'] & {
				color: white;
			}
		}

		.divider {
			margin: 0 12px;
		}

		.strong {
			font-weight: $gotham-black;
		}

		[data-locale='us'] & {
			text-align: center;
			font-size: 12px;
			line-height: 15px;
			text-transform: uppercase;

			padding: 15px 0;
		}

		[data-locale='za'] & {
			text-align: center;
			font-size: 16px;
			line-height: 15px;
			text-transform: uppercase;

			padding: 5px 0;
		}

		[data-theme='velo'] & {
			font-size: 16px;
			line-height: 18px;
			font-family: $font-family-velo;
			font-weight: $velo-medium;
			padding: 10px 0px;
		}
	}

	.video {
		width: 95%;
		position: relative;

		[data-locale='za'] & {
			width: 218px;
		}

		img {
			width: 331px;
			height: auto;

			[data-locale='us'] & {
				width: 279px;
			}
		}

		img.glo {
			width: 243px;
		}

		.playIcon {
			position: absolute;
			top: 36%;
			height: 41px;
			left: 41%;

			[data-locale='us'] & {
				position: absolute;
				top: 21%;
				height: 41px;
				left: 39%;
			}

			[data-locale='za'] & {
				position: absolute;
				top: 28%;
				height: 41px;
				left: 39%;
			}
		}

		[data-locale='us'] & {
			width: 63%;
		}

		[data-theme='velo'] & {
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
		}
	}

	.firstGroup,
	.secondGroup {
		position: relative;

		[data-theme='velo'][data-locale='us'] & {
			width: 534px;
		}
	}

	h2 {
		margin: rescale(45px) 0 rescale(24.5px);

		font-size: rescale(30px);
		letter-spacing: -0.01em;
		line-height: 1.23;

		&.vype {
			font-family: $font-family-gotham;
			font-weight: $gotham-book;
			line-height: 1.1;
			letter-spacing: 0em;
			color: white;

			text-align: left;
			text-transform: uppercase;

			[data-locale='de'] & {
				font-family: $font-family-trim;
				font-weight: $trim-black;
				font-size: 60px;
				line-height: 0.94;

				margin: 45px 0 30px;
			}
		}

		&.dunhill {
			font-family: $font-family-gotham;
			font-weight: $gotham-book;
			line-height: 1.1;
			letter-spacing: 0em;
			color: white;

			text-align: left;
			text-transform: uppercase;

			[data-locale='de'] & {
				font-family: $font-family-euclidFlex;
				font-weight: $euclidFlex-light;
				font-size: 46px;
				line-height: 1.3;
				letter-spacing: .5rem;

				margin: 5px 0 30px;
			}
		}

		&.vuse {
			font-family: $font-family-gotham;
			font-weight: $gotham-book;
			line-height: 1.1;
			letter-spacing: 0em;
			color: white;
			width: 65%;
			text-align: left;

			[data-locale='uk'] & {
				font-family: $font-family-trim;
				font-weight: $trim-black;
				font-size: 52px;
				line-height: 0.94;

				margin: 45px 0 30px;
			}

			[data-locale='za'] & {
				font-family: $font-family-trim;
				font-weight: $trim-black;
				font-size: 52px;
				line-height: 0.94;
			}
		}

		&.glo {
			font-family: $font-family-itcAvant;
			font-weight: $itcAvant-demi;
			line-height: 1.227;
			letter-spacing: 0em;
			color: white;

			text-align: left;

			[data-locale='za'] & {
				font-family: $font-family-mont;
				font-weight: $mont-heavy;
				font-size: 56px;

				line-height: 1;

				margin: 28px 0 35px;
			}
		}

		&.velo,
		&.lyft,
		&.epok {
			font-family: $font-family-gotham;
			font-weight: $gotham-light;
			color: black;

			margin: 39px 0 28px 0;

			[data-locale='uk'] & {
				font-family: $font-family-velo;
				font-weight: $velo-regular;
				font-size: 42px;
				letter-spacing: 0.01;
				line-height: 1.142em;
				color: white;
			}

			[data-locale='za'] & {
				color: white;
				font-family: $font-family-velo;
				font-weight: $velo-regular;
				font-size: 42px;
				letter-spacing: 0.01em;
				line-height: 1.14;
			}
		}
	}

	p {
		margin: 0;

		font-family: $font-family-gotham;
		color: white;

		&.vype {
			margin-bottom: 60px;

			font-size: rescale(18px);
			line-height: 1.571;
			letter-spacing: 0em;
			font-weight: $gotham-light;
		}

		&.dunhill {
			margin-bottom: 60px;

			font-size: rescale(18px);
			line-height: 1.571;
			letter-spacing: 0em;
			font-weight: $gotham-light;
		}

		&.vuse {
			margin-bottom: 60px;

			font-size: 23px;
			line-height: 1.571;
			letter-spacing: 0em;
			font-weight: $gotham-light;

			[data-locale='uk'] & {
				font-size: 27px;
				letter-spacing: -0.01em;
				line-height: 1.48;
			}

			[data-locale='za'] & {
				font-size: 26px;
				letter-spacing: -0.01em;
				line-height: 1.48;
			}
		}

		&.glo {
			margin-bottom: 60px;

			font-size: rescale(18px);
			line-height: 1.571;
			letter-spacing: 0em;
			font-weight: $gotham-light;

			[data-locale='za'] & {
				margin-bottom: 110px;
			}
		}

		&.velo,
		&.lyft,
		&.epok {
			margin-bottom: 20px;

			color: black;
			font-weight: $gotham-light;
			font-size: rescale(18px);
			line-height: 1.571;
			letter-spacing: 0em;

			[data-locale='za'] & {
				color: white;
			}

			[data-locale='us'] & {
				margin-bottom: 27px;
			}

			[data-locale='uk'] & {
				letter-spacing: -0.01em;
				font-size: 25px;
				line-height: 1.4;
				color: white;
			}
		}
	}

	.videosContainer {
		display: flex;
		flex-flow: row nowrap;

		.videoContainer {
			display: flex;
			flex-direction: column;
		}
	}
}

:global(.trademark) {
	top: -2.3em;
	font-size: 0.25em;
}

:global(.trademarkSmallFont) {
	top: -1em;
	font-size: 0.45em;
}

:global(.glo) {
	font-family: $font-family-itcAvant;
	font-weight: $itcAvant-demi;
}

// -- US Styles -- //

[data-locale='us'] {
	.productsInfo {
		&.velo {
			padding: rescale(75px) rescale(45px) rescale(18.6px) rescale(100px);
		}

		&.velo .firstGroup {
			.logo {
				width: rescale(210px);
			}
		}

		h2 {
			margin: rescale(40px) 0 rescale(24.5px);
			width: 100%;

			text-transform: uppercase;
			font-size: 36px;

			&.velo {
				margin: calc(var(--scale-factor) * 17px) 0 calc(var(--scale-factor) * 24.5px);
				font-family: $font-family-velo;
				font-weight: $velo-bold;
				font-size: 44px;
				color: white;
			}
		}

		p {
			&.velo {
				color: white;
				font-size: 24px;
				line-height: 32px;
				font-family: $font-family-velo;
				font-weight: $din-pro-medium;
			}

			&.vuse {
				font-family: $gotham-book;
				font-weight: 400;
				line-height: 28px;

				margin-bottom: 30px;
			}
		}
	}
}

.ctaWrapper {
	display: flex;
}