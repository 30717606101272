@import '../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../node_modules/bat-components/dist/styles/includes/functions';

.block {
	position: relative;

	font-family: $font-family-gotham;
	color: white;
	text-align: center;

	display: flex;
	flex-direction: column;
	min-height: rescale(580px);
	height: 100%;

	white-space: pre-line;

	flex: calc(90% / var(--cols)) 0;
	padding: 80px calc((1 / var(--cols) * 340px) / 2) rescale(40px);

	&:not(:first-child) {
		&::before {
			content: '';

			border: 0.5px solid white;

			position: absolute;
			height: 98%;
			left: 0;
			top: 2%;
		}
	}

	[data-locale='de'] & {
		min-height: 950px;
		padding: 30px calc((1 / var(--cols) * 190px) / 2) rescale(40px);
	}

	[data-locale='us'] & {
		padding: 32px calc((1 / var(--cols) * 374px) / 2) calc(var(--scale-factor) * 40px);
		min-height: 0;
		height: calc(100% - 100px);
	}

	[data-locale='uk'] & {
		padding: 181px calc((1 / var(--cols) * 374px) / 2) 0px;
		min-height: 0;

		&::before {
			border: none;
		}

		&:not(:first-child) .logoImg {
			height: 120px;
			width: auto;
		}
	}

	[data-locale='pl'] & {
		height: calc(100% - 74px);
	}

	[data-locale='za'] & {
		height: calc(100% - 74px);
		margin-top: 74px;

		padding: 80px calc((1 / var(--cols) * 374px) / 2) 0px;
		min-height: 807px;

		&::before {
			border: none;
		}

		&:not(:first-child) .logoImg {
			height: 90px;
			width: auto;
		}
	}

	[data-locale='pl'] & {
		flex: calc(100% / var(--cols)) 0;
		padding: 21px calc((1 / var(--cols) * 340px) / 2) rescale(40px);
	}
}

.title {
	text-align: center;
	font-weight: $gotham-black;
	font-size: rescale(27px);
	letter-spacing: -0.02em;
	line-height: 1;

	text-transform: uppercase;

	margin-bottom: 26px;

	[data-locale='us'] & {
		margin-bottom: 0;
	}
	[data-locale='uk'] & {
		letter-spacing: 0;
	}
	[data-locale='za'] & {
		margin-bottom: 15px;
	}
}

.detail {
	text-align: center;
	font-weight: 400;
	font-size: rescale(16px);
	letter-spacing: -0.02em;
	line-height: 1.3333;

	margin-bottom: 30px;

	white-space: pre-wrap;

	[data-locale='us'] & {
		font-size: 26px;
	}
}

.copyWrapper {
	min-height: rescale(175px);
	display: flex;
	flex-direction: column;

	[data-locale='us'] & {
		justify-content: space-evenly;
	}
	[data-locale='za'] & {
		min-height: 190px;
	}
	[data-locale='uk'] & {
		min-height: 225px;
	}

	[data-locale='pl'] & {
		min-height: rescale(149px);
	}
}

.bulletPoints {
	text-align: left;

	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: rescale(17px);
	line-height: 1.307;
	letter-spacing: -0.022em;

	min-height: rescale(220px);
	padding: 0 rescale(18px);

	.wrapper {
		display: flex;
		flex-flow: row nowrap;

		border-top: 1px solid rgba(white, 0.4);

		padding: rescale(14px) 0px rescale(16px);

		&:last-child() {
			border-bottom: 1px solid rgba(white, 0.4);
		}

		[data-locale='us'] & {
			align-items: end;
			font-size: 25px;
		}

		[data-locale='uk'] & {
			font-size: 24px;
		}
	}

	.icon {
		flex: 0 0 auto;
		width: rescale(26px);
		height: auto;

		margin-right: 27px;

		position: relative;
	}

	&.smush {
		font-size: rescale(16px);
	}
}

.ctaWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: auto;

	button,
	a {
		font-size: rescale(13px);
	}
}

.divider {
	@extend %divider;
}

.logoImg {
	width: rescale(85px);
	height: auto;

	margin-bottom: rescale(16px);

	[data-locale='za'] & {
		margin-top: 30px;
		height: 75px;
		margin-bottom: 8px;
	}
}

// US Styles

[data-locale='us'] {
	.logoImg {
		display: none;
	}
	.block {
		color: black;
	}
	.bulletPoints .icon {
		stroke: black;
		fill: black;
	}
	.wrapper {
		border-top: 1px solid rgba(rgb(8, 8, 8), 0.4);

		&:last-child() {
			border-bottom: 1px solid rgba(rgb(2, 2, 2), 0.4);
		}
	}
}
