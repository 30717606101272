@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.columnsWrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	white-space: pre-line;
	text-align: center;
	color: white;

	height: 100%;
	padding: 30px 180px 0;

	.image {
		width: 130px;
		height: 130px;
		margin-bottom: 15px;
	}

	.title {
		font-family: $font-family-trim;
		font-weight: $trim-black;
		font-size: 64px;
		line-height: 1.0625;
		letter-spacing: 0.01em;

		margin-bottom: 50px;
	}

	.copy {
		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		font-size: 24px;
		line-height: 1.5;
		letter-spacing: 0;

		margin-bottom: 60px;
	}

	.column {
		width: 686px;
		flex: 0 0 auto;

		.splitButton {
			margin-top: 60px;

			position: relative;
			bottom: unset;
		}
	}
}
