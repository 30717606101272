@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';

@mixin price {
	font-family: $font-family-gotham;
	font-weight: $gotham-light;
	font-size: calc(var(--scale-factor) * 30px);
	letter-spacing: 0em;
	line-height: 1.2;

	[data-locale='us'] & {
		font-size: 30px;
	}
}

.copy {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 24px;
	letter-spacing: -0.01em;
	line-height: 1.5;

	white-space: pre-wrap;

	margin: 30px 0 37px;

	[data-locale='za'][data-theme='velo'] & {
		color: white;
	}
	[data-locale='uk'][data-theme='velo'] & {
		color: white;
		margin-top: 20px;
		margin-bottom: 50px;

		font-family: $font-family-velo;
		font-weight: $velo-regular;
	}
	[data-locale='za'] & {
		font-size: 23px;
	}
}

[data-locale='za'] [data-layout='reduced'].glo {
	flex-basis: 510px !important;
	padding-right: 40px !important;
}

.details {
	position: relative;
	flex: 0 0;
	flex-basis: rescale(460px);
	align-items: flex-end;

	text-align: right;
	padding-right: rescale(45px);
	padding-left: rescale(45px);

	[data-theme='dunhill'] & {
		flex-basis: rescale(400px);
		padding-left: 0;
	}

	[data-locale='us'] & {
		&.velo {
			align-items: flex-start;
			padding-right: 0;
		}
	}

	[data-locale='uk'] & {
		&.vuse {
			padding-right: 140px;
			padding-left: 0;
			padding-top: 80px;
		}

		&.velo {
			padding-left: 130px;
			padding-right: 0;
		}
	}

	[data-locale='uk'] [data-page='accessories'] & {
		max-width: 510px;
		padding-top: 10px;
		padding-right: 25px;
		padding-bottom: 20px;
	}

	[data-locale='za'] [data-page='accessories'] & {
		max-width: 510px;
		padding-top: 10px;
		padding-right: 25px;
		padding-bottom: 20px;
	}

	[data-locale='za'] & {
		padding: 0 25px 0 0;
	}

	&.glo {
		flex: 0 0;
		flex-basis: rescale(370px);

		position: relative;
		padding: 0;

		.price {
			margin-top: 38px;

			font-family: $font-family-itcAvant;
			font-weight: $itcAvant-book;
			font-size: rescale(28px);

			[data-locale='de'] & {
				font-size: rescale(22px);
			}
		}
	}

	&.velo,&.glo {
		[data-locale='za'] & {
			flex-basis: 570px;
		}
	}
	&.velo {
		[data-locale='za'] & {
			padding-right: 50px;
		}
	}
	&.velo,
	&.epok,
	&.lyft {
		flex: 0 0;
		flex-basis: rescale(400px);
	}
	&.velo,
	&.lyft,
	&.epok {
		.price {
			font-weight: $gotham-book;
			margin-top: 0;
		}
	}

	.productDetailContainer {
		margin-bottom: 10px;
		margin-top: 60px;
		font-weight: $gotham-medium;
		font-size: rescale(40px);
		letter-spacing: 0em;
		line-height: 0.9;

		[data-locale='uk'] & {
			margin-top: 30px;
			margin-bottom: 20px;
		}

		[data-locale='de'][data-theme='glo'] & {
			margin-bottom: 25px;
		}

		[data-locale='de'][data-theme='dunhill'] & {
			margin-top: 25px;
			margin-bottom: 0px;
		}

		[data-locale='za'][data-theme='glo'] & {
			margin-top: 30px;
		}
		[data-locale='za'][data-theme='velo'] & {
			color: white;
			margin-top: 35px;

			.productDetail {
				font-family: $font-family-velo;
				font-weight: $velo-bold;
				font-size: 56px;
				line-height: 1;
				letter-spacing: 0;
			}
		}

		[data-locale='us'] &.velo {
			align-items: flex-start;
			font-family: $font-family-velo;
			font-weight: $din-black-italic;
			font-style: italic;
		}

		div {
			white-space: pre-line;
		}

		.glo {
			font-family: $font-family-itcAvant;
			font-weight: $itcAvant-book;
			font-size: rescale(40px);
			line-height: 0.73;
			letter-spacing: -0.01em;

			[data-locale='za'] & {
				font-family: $font-family-mont;
				font-weight: $mont-heavy;
				font-size: 60px;
				line-height: 1;
				letter-spacing: 0;
			}

			span,
			div {
				font-weight: $itcAvant-demi;
			}
		}

		.velo {
			font-family: $font-family-euclidFlex;
			font-weight: $euclidFlex-light;
			font-size: rescale(40px);
			line-height: 0.73;
			letter-spacing: -0.01em;

			[data-locale='uk'] & {
				font-size: 56px;
				line-height: 1;
				font-family: $font-family-velo;
				font-weight: $velo-bold;
			}
		}
	}

	h2 {

		[data-locale='de'] & {
			line-height: 1;
		}

		span {
			font-family: $font-family-gotham;
			font-weight: $gotham-book;
			font-size: rescale(28px);
			line-height: 1;
			letter-spacing: 0em;

			&.glo {
				font-family: $font-family-itcAvant;
				font-weight: $itcAvant-book;
				font-size: rescale(28px);
				line-height: 1.285;
				letter-spacing: 0em;

				[data-locale='de'] & {
				line-height: 1;
				font-size: rescale(22px);
				}
			}
		}
	}
}

.logo {
	width: 75px;
	height: auto;

	[data-theme='glo'][data-locale='za'] & {
		width: 100px;
	}

	[data-locale='uk'][data-theme='vuse'] [data-page='accessories'] & {
		height: 56px;
		width: auto;
	}
	[data-locale='za'][data-theme='vuse'] [data-page='accessories'] & {
		height: 56px;
		width: auto;
	}

	[data-locale='de'][data-theme='dunhill']  & {
		height: 80px;
		width: auto;
	}
}

.smallPrint {
	color: white;
	font-size: 15px;
	letter-spacing: -0.01em;
	line-height: 18px;
	font-weight: $gotham-light;

	margin-top: 35px;
}

.priceSmallPrint {
	font-weight: $gotham-medium;
	font-size: 14px;

	margin-top: 10px;
	text-transform: uppercase;
}

[data-locale='us'][data-theme='vuse'] .priceContainer {
	display: flex;
	margin-bottom: 20px;
}

[data-locale='us'][data-theme='velo'] .priceContainer {
	display: flex;
	margin-bottom: 20px;
}

.completeKit {
	grid-column: 1/3;
	grid-row: 1/2;

	@include price;
}

.priceContainer .price {
	@include price;
	margin-top: 20px;
	white-space: pre-line;

	[data-theme='dunhill'] & {
	margin-top: 0px;
	}

	[data-locale='us'] & {
		grid-column: 1/2;
		grid-row: 2/3;
		margin-top: 0;
		justify-self: end;
	}

	[data-locale='uk'] & {
		font-size: 44px;
		line-height: 1;
	}

	[data-locale='za'] [data-layout='reduced'] & {
		font-size: 44px;
		letter-spacing: 0;
		margin-top: 5px;
	}
}

.plusTax {
	grid-column: 2/3;
	grid-row: 2/3;

	font-size: 10px;
	color: white;

	padding: 5px;
	padding-left: 8px;
	justify-self: end;
}

.iconsContainer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin: 28px -39px 40px 84px;

	.icons {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr auto;
		width: 80%;
		justify-content: space-evenly;
		text-align: center;
		font-size: 14px;

		[data-locale='uk'][data-theme='vuse'] & {
			width: 84%;
		}
		[data-locale='uk'][data-theme='velo'] & {
			width: 89%;
		}

		.iconImg {
			height: 75px;
		}

		.iconLabel {
			white-space: pre-line;
			text-transform: uppercase;

			[data-locale='uk'] & {
				font-size: 14px;
			}

			[data-locale='uk'][data-theme='velo'] & {
				font-size: 13px;
				text-transform: unset;
			}
		}
	}
}

.hidden {
	display: none;
}

// -- regional styling

[data-locale='us'] {
	.vuse {
		.price {
			color: white;
			display: flex;
			font-size: 30px;
			font-family: $gotham-book;
			font-weight: normal;
			font-weight: 400;
		}
	}

	.velo {
		.price {
			color: white;
			display: flex;
			font-family: $font-family-velo;
			font-weight: $velo-bold;
			align-self: start;
			font-size: 48px;
		}

		.productDetailContainer {
			margin-top: 20px;
		}

		.plusTax {
			justify-content: flex-start;
			margin-top: 7px;
			margin-left: 5px;
		}
	}

	.plusTax {
		display: flex;
		flex-direction: column;
		text-align: left;
		padding-bottom: 5px;
		justify-content: flex-end;
	}

	.productDetail {
		&.vuse {
			font-size: 44px;
			font-family: $gotham-book;
			font-weight: unset;
			text-transform: uppercase;
		}
		&.velo {
			font-family: $font-family-velo;
			font-weight: $velo-bold;
			color: white;
			font-size: 48px;
			line-height: 48px;
			text-align: left;
		}
	}

	.logo {
		&.velo {
			width: 254px;
			margin-top: 54px;
			align-self: start;
		}
	}

	.btnContainer {
		&.vuse {
			width: 55%;
			margin-left: auto;
		}
		&.velo {
			align-self: start;
		}
		button {
			[data-theme='velo'] & {
				margin: 0;
			}
		}
	}
}

// Regional styling SA
[data-locale='uk'][data-theme='vuse'],
[data-locale='za'][data-theme='vuse'] {
	.productDetail {
		font-family: $font-family-trim;
		font-weight: $trim-black;
		font-size: 58px;
		line-height: 0.94;

		[data-layout='reduced'] & {
			font-size: 60px;
			line-height: 1;
			letter-spacing: 0;
		}
	}

	.productDetailContainer {
		margin-top: 25px;
	}

	.price {
		font-size: 30px;
		font-weight: $gotham-book;
	}

	.logo {
		width: 78.5px;
		height: auto;
		margin-bottom: 10px;
	}

	.iconsContainer {
		margin: 15px -35px 38px 0;

		.iconImg {
			height: 50px;
			margin-bottom: 15px;

			[data-locale='uk'] & {
				height: 60px;
			}
		}
	}
}

[data-locale='de'][data-theme='vype'] {
	.productDetail {
		font-family: $font-family-trim;
		font-weight: $trim-black;
		font-size: 58px;
		line-height: 0.94;

		[data-layout='reduced'] & {
			font-size: 60px;
			line-height: 1;
			letter-spacing: 0;
		}
	}
}

[data-locale='de'][data-theme='dunhill'] {
	.productDetail {
			font-family: $font-family-euclidFlex;
			font-weight: $euclidFlex-light;
			font-size: 46px;
			line-height: 1.3;
			letter-spacing: .25rem;
			text-transform: uppercase;
	}

	.productSubtitle {
		display: block;
		font-family: $font-family-gotham;
		font-size: 36px;
		letter-spacing: 0;
	}

	.price {
		font-size: 38px;
	}
}


.details .btnContainer {
	[data-locale='uk'][data-theme='velo'] & {
		button {
			font-size: 18px;
			letter-spacing: 0;
			padding: 16px 35px;
			line-height: 1;
		}
	}

	.smallStyle {
		font-size: 18px;
		letter-spacing: 0;
		font-weight: $gotham-black;
		padding: 16px 35px;
		line-height: 1;
	}
}

[data-page='accessories'] {
	h2 {
		line-height: 0;
	}

	.productSubtitle {
		display: block;
		font-family: $font-family-gotham;
		font-size: 18px;
		line-height: 28px;
		letter-spacing: 0;
		color: white;
		margin: 0 0 7px 0;
	}
}

.left {
	align-items: flex-start;
	text-align: left;
}
