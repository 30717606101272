@import '../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../node_modules/bat-components/dist/styles/includes/functions';

$flex-basis: calc(var(--percentage-cols, 130%) / var(--blocks_cols, 4));
$flex-basis1: calc(130% / var(--blocks_cols, 4));
$flex-basis2: calc(125% / var(--blocks_cols, 4));
$flex-basis3: calc(145% / var(--blocks_cols, 4));

.block {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: $flex-basis;

	position: relative;
	width: $flex-basis;
	min-height: 100%;
	margin-right: var(--margin, -6.5%);
	margin-left: 0;
	padding-right: 1%;

	font-family: $font-family-gotham;
	color: white;
	text-align: center;

	white-space: pre-line;

	clip-path: var(--clip-path);

	&:first-of-type {
		clip-path: var(--clip-path-border);
	}

	&:last-of-type {
		padding-right: 3%;
	}
}

.blockInner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.button {
		min-width: 300px;
		padding: 10px 15px !important;
		margin-top: 190px;
		margin-bottom: 40px;
		margin-right: 70px;

		letter-spacing: 0.05em !important;

		[data-locale='za'] & {
			font-size: 20px;
		}
	}
}

.blockStart {
	margin-top: 25px;
}

.detail {
	text-align: center;
	font-weight: $gotham-book;
	font-size: 32px;
	letter-spacing: 0;
	line-height: 1.176;

	padding: 0px 30px;
	margin-bottom: 55px;
	margin-right: 80px;
	min-height: 100px;

	white-space: pre-wrap;
}

.logoImgContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.logoImg {
		flex: 0 0 auto;
		width: auto;
		height: 231px;
	}
}

.blockThree {
	width: $flex-basis1;
	flex-basis: $flex-basis1;

	&:first-of-type {
		width: $flex-basis2;
		flex-basis: $flex-basis2;
	}

	&:last-of-type {
		padding-right: 6% !important;
		width: $flex-basis3;
		flex-basis: $flex-basis3;
	}
}

.blockTwo {
	background-size: contain !important;
	background-repeat: no-repeat !important;

	button {
		font-size: calc(var(--scale-factor) * 14px);
	}

	&:first-of-type {
		padding-right: 6% !important;

		img {
			height: 280px;
		}
	}

	&:last-of-type {

		img {
			height: 280px;
		}

		padding-right: 6% !important;
		margin-right: -8% !important;
	}
}