@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.backButton.customisedToProduct {
	position: absolute;
	bottom: 28px;
	padding: 20px 75px;
	transform: translateX(-50%);
	border: none !important;
	line-height: 1.1;
	margin: 0 auto;

	[data-theme='vuse'] & {
		color: white !important;
		background: linear-gradient(90deg, rgba(241, 176, 44, 1) 0%, rgba(235, 92, 33, 1) 100%);
	}

	[data-theme='velo'] & {
		color: white !important;
		background: #69abd2;
	}

	[data-theme='glo'][data-locale='de'] & {
		background: #edae48;
	}

	[data-theme='glo'] & {
		background: white;
	}
}

.backButton.white {
	position: absolute;
	bottom: 28px;
	padding: 20px 75px;
	transform: translateX(-50%);
	border: none !important;
	line-height: 1.1;

	background: 'white';
	color: #69abd2 !important;
}
