@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';

[data-layout='reduced'].description {
	padding: 0 0 0 25px;

	[data-theme='glo'] & {
		flex-basis: 475px;
	}

	[data-theme='glo'][data-locale='za'] & {
		flex-basis: 510px;
	}

	[data-theme='velo'] & {
		flex-basis: 570px;
		padding-left: 50px;
	}
}

.description {
	flex: 0 0;
	flex-basis: rescale(460px);

	padding: 0 0 0 rescale(40px);

	[data-theme='dunhill'] & {
		flex-basis: rescale(400px);
	}

	text-align: left;

	&.glo {
		flex: 0 0;
		flex-basis: rescale(370px);
		position: relative;
		padding: 0;

		[data-locale='de'][data-theme='glo'] & {
			flex-basis: 570px;
		}
	}
	&.velo,
	&.lyft,
	&.epok {
		flex: 0 0;
		flex-basis: rescale(400px);
	}

	[data-locale='us'] & {
		&.velo {
			color: white;
		}
		&.vuse {
			padding-left: 20px;
		}
	}
	[data-locale='uk'] & {
		&.vuse {
			padding-left: 96px;
			padding-right: 0;
			padding-top: 50px;
		}
	}

	[data-locale='uk'] [data-page='accessories'] & {
		padding-left: 25px;
		max-width: 510px;
	}

	[data-locale='za'] [data-page='accessories'] & {
		padding-left: 25px;
		max-width: 510px;
	}

	.copySubtitle {
		margin-bottom: 23px;
		font-weight: $gotham-book;
	}

	.copyTitle {
		margin-bottom: 23px;
		white-space: pre-wrap;
		font-weight: $gotham-medium;

		[data-locale='de'][data-theme='glo'] & {
			font-size: 20px;
			font-weight: $gotham-bold;

			line-height: 1.3;
			letter-spacing: 0.01em;
			margin: 0;
		}

		[data-locale='de'][data-theme='dunhill'] & {
			font-size: 30px;
		}

		[data-page='accessories'] & {
			margin-bottom: 0;
		}

		[data-locale='us'] & {
			text-transform: uppercase;
			font-size: 30px;
			line-height: 36px;
			font-weight: $gotham-black;

			margin-right: 30px;
			&.velo {
				font-size: 36px;
			}
		}
		[data-locale='uk'][data-theme='velo'] & {
			text-transform: uppercase;
			font-size: 26px;
			line-height: 34px;
			letter-spacing: -0.01em;
			font-weight: $gotham-medium;

			margin: 0px;
		}
	}

	.bulletPoints {
		[data-locale='uk'][data-theme='velo'] & {
			font-family: $font-family-gotham;
			font-weight: $gotham-book;
			font-size: 22px;
			line-height: 26px;
			letter-spacing: -0.01em;
		}
	}

	.productsDetail {
		[data-locale='de'][data-theme='glo'] & {
			font-size: 20px;

			line-height: 1.3;
			letter-spacing: 0.01em;
			margin: 0;
		}

		[data-locale='de'][data-theme='dunhill'] & {
			font-size: 26px;
		}


		[data-locale='uk'][data-theme='velo'] & {
			font-family: $font-family-gotham;
			font-weight: $gotham-book;
			font-size: 26px;
			letter-spacing: -0.01em;
		}
	}

	.productsDetail,
	.bulletPoints {
		white-space: pre-line;

		font-size: rescale(18px);
		line-height: 1.42;
		letter-spacing: 0em;
		font-family: $font-family-gotham;
		font-weight: $gotham-light;

		&.reduced {
			font-size: rescale(16px);
			line-height: 1.25;
			letter-spacing: 0.024em;

			[data-locale='us'] & {
				font-size: 26px;

				&.vuse {
					font-size: 23px;
				}

				&.velo {
					padding-right: 10px;
				}
			}
		}
	}

	.bulletPoints {
		padding-inline-start: rescale(18px);
		padding-top: rescale(15px);

		margin-block-start: 0px;
		margin-block-end: rescale(8px);

		li {
			margin-bottom: rescale(13px);
			list-style-type: disc;
		}

		[data-locale='us'] & {
			&.velo {
				font-family: $font-family-velo;
			}
		}
	}
}

[data-locale='us'] {
	.velo {
		.copyTitle {
			font-family: $font-family-velo;
			font-weight: $velo-bold;
			line-height: 36px;
			font-size: 36px;

			margin-right: 0;
		}
	}
	.velo .productsDetail {
		font-family: $font-family-velo;

		font-size: 26px;
	}
}

// --- Regional style
[data-locale='uk'],
[data-locale='za'] {
	.copyTitle {
		font-size: 30px;
		line-height: 1.2;
	}

	.productsDetail.reduced {
		font-size: 24px;
	}

	.bulletPoints.reduced {
		padding-top: 25px;
		padding-bottom: 5px;

		font-size: 22px;
	}
}

[data-page='accessories'] .copySubtitle,
[data-page='accessories'] .copyTitle {
	font-size: 26px;
}

.icons {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 10px;

	font-size: 14px;

	.icon {
		display: flex;
		flex-flow: row nowrap;

		margin-bottom: 30px;

		[data-locale='za'][data-theme='velo'] & {
			margin-bottom: 25px;
		}
	}

	.iconImg {
		flex: 0 0 auto;
		height: 100px;
		width: 100px;

		margin-right: 20px;
	}

	.text {
		display: flex;
		flex-direction: column;
		align-self: center;

		[data-locale='za'][data-theme='velo'] & {
			color: white;
		}

		[data-locale='uk'][data-theme='velo'] & {
			color: white;
		}
	}

	.iconTitle {
		display: block;

		font-family: $font-family-gotham;
		font-weight: $gotham-black;
		font-size: 21px;
		line-height: 1.272;
		letter-spacing: 0;
		text-transform: uppercase;

		[data-locale='za'][data-theme='velo'] & {
			color: white;
		}

		[data-locale='uk'][data-theme='velo'] & {
			color: white;
		}

		[data-theme='glo'] & {
			font-family: $font-family-mont;
			font-weight: 600;
			font-size: 22px;
			letter-spacing: 0.015em;
			line-height: 1.36;
		}
	}

	.iconLabel {
		display: block;

		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		font-size: 20px;
		line-height: 26px;
		letter-spacing: 0;

		[data-locale='za'][data-theme='velo'] & {
			color: white;
		}

		[data-locale='uk'][data-theme='velo'] & {
			color: white;
		}
	}
}
