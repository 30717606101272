@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';

.product {
	flex: 0 0;
	flex-basis: rescale(120px);
	align-items: center;

	[data-page='accessories'] & {
		flex-basis: 400px !important;
	}

	[data-locale='uk'] & {
		flex-basis: 174px;
	}

	[data-theme='dunhill'] & {
		flex-basis: 450px;
	}

	&.glo {
		height: rescale(525px);
		width: rescale(338px);

		flex: 0 0 auto;

		[data-locale='za'] & {
			max-height: calc(100vh - 108px);
			height: 100%;
		}
	}
	&.velo,
	&.lyft,
	&.epok {
		flex: 0 0;
		flex-basis: rescale(280px);

		[data-locale='uk'] & {
			flex-basis: 490px;
		}
	}

	.productImage {
		position: absolute;

		height: rescale(525px);
		width: rescale(120px);

		object-fit: contain;
		object-position: center;

		[data-locale='uk'][data-theme='vuse'] &,
		&.velo,
		&.glo,
		&.epok,
		&.lyft {
			height: 100%;
			width: 100%;
		}

		[data-theme='glo'] &.glo {
			height: 690px;
			margin-top: 15px;
		}

		[data-theme='dunhill'] &.dunhill {
			width: 90%;
		}
	}

	.productImage {
		position: absolute;

		height: rescale(525px);
		width: rescale(120px);

		object-fit: contain;
		object-position: center;

		[data-locale='uk'] [data-page='accessories'] & {
			height: 780px;
			width: 400px;
			margin-bottom: 30px;
		}
	}
}

[data-locale='us'] {
	.product.velo {
		flex-basis: calc(var(--scale-factor) * 372px);
		padding-bottom: 120px;
	}

	.product.vuse {
		flex-basis: calc(var(--scale-factor) * 238px);

		.productImage {
			height: 701px;
			width: calc(var(--scale-factor) * 230px);
			padding-bottom: 70px;
		}
	}
}


[data-locale='de'] [data-center-layout='reduced']  {
	flex-basis: 350px;
	.productImage {
		height: auto;
		width: 90%;
		margin-bottom: 50px;
		margin-right: 0 !important;
	}
}

[data-locale='za']  {
	.product.vuse {
		flex-basis: 350px;

		.productImage {
			height: auto;
			width: 100%;
			margin-bottom: 50px;
			margin-right: 0 !important;
		}
	}

		.product.vype {
			flex-basis: 350px;
	
			.productImage {
				height: auto;
				width: 90%;
				margin-bottom: 50px;
				margin-right: 0 !important;
			}
		}

		.product.dunhill {
			flex-basis: 350px;
			.productImage {
				height: auto;
				width: 90%;
				margin-bottom: 50px;
				margin-right: 0 !important;
			}
		}
	
	.product.velo {
		flex-basis: 520px;
		.productImage {
		margin-right: 0 !important;
		}
	}

	.product.glo {
		.productImage {
			margin-right: 0 !important;
			}
	}
}

.textUnder {
	margin-top: auto;
	margin-bottom: 140px;

	font-family: $font-family-velo;
	font-weight: $velo-regular;
	font-size: 24px;
	letter-spacing: -0.01em;
	line-height: 1.16;

	text-align: center;
}
