@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.nav {
	position: fixed;
	display: flex;
	align-items: center;

	width: 100%;

	top: 0;
	left: 0;
	z-index: 10;

	background-color: #f5f5f6;
	box-shadow: 0px 2px 12px rgba(#5e5e5e, 0.5);

}

[data-locale='za'] {
	.nav {
		height: 76px;
	}
}

[data-locale='us'] {
	.nav {
		bottom: 0;
		top: unset;
		position: absolute;
	}

	.offset {
		position: relative;
	}
}

.offset {
	width: 100%;
	height: 76px;
	position: absolute;

	[data-locale='za'] & {
		display: none;
	}
}

.links {
	display: flex;

	margin-left: auto !important;

	overflow: hidden;
}

.link {
	padding: 25px 44px;
	display: block;
	text-align: center;

	color: #5e5e5e;

	font-family: $font-family-gotham;
	text-transform: uppercase;

	background-color: transparent;
	appearance: none;
	border: none;

	&.active {
		color: #fff;
		background-color: #5e5e5e;

		&::before {
			content: initial !important;
		}
	}
	[data-locale='us'] & {
		padding: 32px 44px;

		color: black;

		&.active {
			color: #fff;
		}
	}
}

.back {
	color: #777777;

	font-family: $font-family-gotham;
	font-weight: $gotham-medium;
	letter-spacing: 0.04em;
	font-size: rescale(11px);
	text-transform: uppercase;

	margin-left: 8px;

	display: flex;
	align-items: center;

	padding: 0;
	align-self: stretch;
	border: none;
	background-color: transparent;

	cursor: pointer;
}

.logoButton {
	display: flex;
	appearance: none;
	background: none;
	border: 0;
}

.logo {
	width: 50px;
	height: 50px;
	margin: 0px 20px;
}

.pretitle {
	display: block;
	font-size: rescale(10px);
	font-weight: $gotham-medium;
	letter-spacing: 0.08em;
}

.title {
	display: block;
	font-size: rescale(18px);
	font-weight: $gotham-bold;
	line-height: 1.07;
	letter-spacing: -0.01em;

	[data-locale='us'] & {
		font-weight: $gotham-black;
	}
}

.includeArrow {
	position: relative;

	display: flex;
	align-items: center;

	&::before {
		content: '';

		border-style: solid;
		border-width: 0 1px 1px 0;

		width: 14px;
		height: 14px;
		display: block;

		left: 0;

		margin-right: 10px;
	}

	&.left::before {
		transform: rotate(135deg);
	}
}

.item {
	position: relative;

	& + & {
		.link::before {
			content: '';
			position: absolute;
			height: 70%;
			top: 15%;
			left: -1px;

			width: 1px;
			background-color: rgba(#5e5e5e, 0.4);
		}
	}
}
