@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.infoWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	text-align: center;
}
.infoTitle {
	font-family: $font-family-trim;
	font-weight: $trim-black;

	font-size: 68px;
	line-height: 0.941;
	letter-spacing: 0;
	opacity: 0;

	animation-name: appear;
	animation-duration: 0.7s;
	animation-fill-mode: forwards;
}
.columnsWrapper {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
}

.columnWrapper {
	flex: 1 1 auto;
	flex-basis: calc(100% / var(--subscription-cols, 3));

	display: flex;
	flex-direction: column;

	align-items: center;
	margin: 80px 50px 100px;
}
.icon {
	width: 75px;
	height: 75px;

	margin-bottom: 25px;
}
.title {
	font-family: $font-family-gotham;
	font-size: 30px;

	font-weight: $gotham-medium;
	letter-spacing: 0.002em;
	line-height: 2.1;
}
.body {
	color: #b9b9b9;
	font-family: $font-family-gotham;
	font-size: 24px;

	font-weight: $gotham-book;
	letter-spacing: 0;
	line-height: 1.416;
	margin: 5px 0;
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
