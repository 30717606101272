@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	position: relative;
	height: 100%;
	padding-top: 5vh;

	font-family: $font-family-gotham;

	text-transform: uppercase;
	text-align: center;
	white-space: pre-line;
	color: white;

	z-index: 5;

	[data-locale='de'] & {
		padding-top: calc(100px + 5vh);
	}
}

.divider1 {
	margin: 5px auto 17px;

	@extend %divider;
}

.divider2 {
	margin: rescale(5px) auto rescale(20px);

	@extend %divider;
}

.pretitle {
	font-size: rescale(14px);
	line-height: 2;
	letter-spacing: 0.06em;

	font-weight: $gotham-medium;
}

.mainText {
	font-size: rescale(27px);
	line-height: 1;
	letter-spacing: -0.01em;

	margin-bottom: 20px;

	font-weight: $gotham-black;
}

.questionsWrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: flex-start;

	.imgBtn {
		display: contents !important;
		margin: 0;
		padding: 0;
	}

	.questionContainer {
		position: relative;

		display: flex;
		flex: 0 0;
		flex-basis: rescale(320px);
		flex-direction: column;

		align-items: center;
	}

	.headContainer {
		position: relative;
	}

	.text {
		color: white;
		width: 100%;
		padding: 0 9px;
		margin: 0 auto 25px;
		min-height: 40px;
		max-width: 450px;

		font-size: rescale(15px);
		line-height: 1.09;
		letter-spacing: -0.01em;

		font-weight: $gotham-book;
	}

	.btnOption {
		width: rescale(45px);
		height: rescale(45px);

		font-weight: $gotham-book;
		font-size: rescale(18px);
		line-height: 2.3;
		letter-spacing: -0.01em;

		color: black;
		border-radius: 50%;
		padding: 0;

		border: transparent;
		background: white;
	}

	.optionImage {
		width: rescale(320px);
		height: rescale(320px);
		margin: 6% 0;

		object-fit: cover;
		object-position: center;

		max-width: 100% !important;
	}
}
