@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	position: relative;
	height: 100%;
	z-index: 5;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	color: white;

	[data-locale='de'] & {
		padding-top: 100px;
		height: calc(100% - 100px);
	}

	[data-locale='pl'] & {
		padding-bottom: 7vh;
	}

	.columns {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	a {
		width: 90px;

		flex: 0 0 auto;
	}

	.nextProduct,
	.prevProduct {
		margin: 0;
		padding: 0 !important;

		display: flex;
		align-items: center;

		color: white;

		z-index: 2;

		[data-locale='us'] & {
			&.vuse {
				height: 80px;
				width: 120px;
			}
			&.velo {
				height: 121px;
				width: 160px;
			}
		}

		[data-locale='uk'][data-theme='velo'] & {
			color: white;
		}

		img {
			height: rescale(115px);
			width: rescale(115px);
			object-fit: contain;
		}
	}

	.nextProduct {
		justify-content: flex-end;
		padding-right: rescale(15px) !important;

		position: relative;

		&::after {
			content: '';

			border-style: solid;
			border-width: 0 rescale(2px) rescale(2px) 0;
			display: inline-block;
			padding: rescale(4px);

			transform: rotate(-45deg);

			[data-theme='velo'] & {
				border-color: #69abd2;
			}
		}
	}

	.prevProduct {
		justify-content: flex-start;
		padding-left: rescale(15px) !important;

		position: relative;

		&::before {
			content: '';

			border-style: solid;
			border-width: 0 rescale(2px) rescale(2px) 0;
			display: inline-block;
			padding: rescale(4px);

			transform: rotate(135deg);
			[data-theme='velo'] & {
				border-color: #69abd2;
			}
		}
	}

	.currentProduct {
		position: relative;

		display: flex;
		justify-content: center;
		flex-flow: row nowrap;

		width: 100%;
		height: 100%;

		[data-page='accessories'] & {
			padding-bottom: 20px;
		}

		&.velo {
			color: black;

			[data-locale='uk'] & {
				color: white;
			}

			h1 {
				font-family: $font-family-euclidFlex;
				font-weight: $euclidFlex-light;
				text-transform: uppercase;
			}

			.price {
				font-weight: $gotham-book;
				margin-top: 0;
			}
		}

		&.lyft,
		&.epok {
			color: black;

			h1 {
				font-family: $font-family-foundry;
				font-weight: $foundry-normal;
				text-transform: uppercase;
			}

			.price {
				font-weight: $gotham-book;
				margin-top: 0;
			}
		}
	}
}

.arrowText {
	[data-theme='velo'] & {
		height: 100px;
		width: 100px;

		border-radius: 100%;
		background-color: #69abd2;

		font-weight: $gotham-bold;
		font-family: $font-family-gotham;
		font-size: 18px;
		line-height: 1.11;

		display: flex;
		align-items: center;
		justify-content: center;

		padding: 10px;
		margin: 5px;
	}
}
