@import '../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../node_modules/bat-components/dist/styles/includes/functions';

.block {
	position: relative;

	font-family: $font-family-gotham;
	color: white;
	text-align: center;

	white-space: pre-line;

	flex: calc(90% / var(--cols)) 0;

	& + & {
		border-left: rgba(#fff, 0.8) solid 1px;
	}

	[data-locale='uk'] & {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: calc(100% / var(--cols)) 0;

		height: calc(100vh - 40px);
		border-left: none;

		font-weight: $gotham-book;
	}
	[data-locale='us'] & {
		flex: calc(100% / var(--cols)) 0;
		justify-content: space-between;

		height: 80vh;
		border-left: none;
		font-weight: $gotham-book;
	}
}

.blockInner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 10vh calc((1 / var(--cols) * 340px) / 2);

	max-width: rescale(500px);
	margin: 0 auto;

	[data-locale='uk'] & {
		height: 100%;
		max-height: 575px;

		justify-content: space-between;

		padding: 0 calc((1 / var(--cols) * 340px) / 2);
		margin-top: 8vh;
	}

	[data-locale='us'] & {
		height: 100%;
		justify-content: space-between;
	}
}

.blockStart {
	margin-top: 25px;
}

.blockEnd {
	display: flex;
	flex-direction: column;
	align-items: center;

	[data-screentype='qb13r']:not([data-locale='uk']) & {
		width: 48%;
	}
}

.pretitle {
	text-align: center;
	font-weight: 500;
	font-size: rescale(12px);
	letter-spacing: 0.088em;
	line-height: 1.333;

	text-transform: uppercase;

	[data-locale='uk'] & {
		margin-bottom: 4px;
		font-size: 22px;
		letter-spacing: 0.02em;
	}
	[data-locale='us'] & {
		margin-bottom: 6px;
		font-size: 22px;
	}
}

.title {
	text-align: center;
	font-weight: 900;
	font-size: rescale(29px);
	letter-spacing: -0.01em;
	line-height: 1.1;

	text-transform: uppercase;

	[data-locale='uk'] & {
		font-size: 46px;
		font-weight: $gotham-black;
	}
	[data-locale='us'] & {
		margin: auto;
	}
}

.detail {
	text-align: center;
	font-weight: 400;
	font-size: rescale(16px);
	letter-spacing: -0.02em;
	line-height: 1.3333;

	padding: 0px 30px;
	margin-bottom: 33px;

	white-space: pre-wrap;

	[data-locale='uk'] & {
		line-height: 1.142;
		font-size: 28px;
		letter-spacing: 0;
	}
	[data-locale='us'] & {
		font-size: 30px;
		line-height: 36px;
		padding: 0;
	}
}

.imgButton {
	width: rescale(64px);
}

.divider {
	@extend %divider;

	[data-locale='uk'] & {
		width: 120px;
		background-color: white;
		margin: 25px auto 50px;
	}
	[data-locale='us'] & {
		background-color: #ffffff3b;
		margin: 40px auto;
	}
}

.logoImg {
	width: 100px;
	height: auto;

	[data-locale='uk'] &[data-brand='vuse'] {
		width: auto;
		height: 126px;
		margin-bottom: 46px;
	}

	[data-locale='uk'] &[data-brand='velo'] {
		width: auto;
		height: 144px;
		margin-bottom: 29px;
	}
	[data-locale='us'] & {
		width: 146px;
		height: auto;
		margin-bottom: 46px;
	}
}

.disclaimer {
	position: absolute;
	bottom: 25px;
	left: 50%;
	transform: translateX(-50%);

	font-size: 12px;
	font-weight: $gotham-bold;

	width: 445px;
}
