@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	position: relative;
	height: 100%;
	margin: 0 auto;

	text-align: center;
	padding: 150px 0 0 0;
	color: black;

	&[data-layout='eliquids'][data-type='safety'] {
		padding: 245px 0 0 0;
	}

	&[data-layout='eliquids'][data-type='quality'] {
		padding: 160px 0 0 0;
	}

	[data-layout='tingle'] & {
		text-align: left;
	}

	[data-theme='vuse'] & {
		color: white;
	}

	[data-locale='za'][data-theme='velo'] & {
		color: white;
	}

	[data-locale='uk'][data-theme='velo'] & {
		color: white;
	}

	&.qrCodes {
		padding: 210px 0 0 0;
	}
}

.title {
	font-size: 56px;
	text-align: center;

	white-space: pre-line;

	[data-layout='tingle'] & {
		text-align: left;
	}

	[data-theme='velo'] & {
		color: #192a53;
		font-family: $font-family-velo;
		font-weight: $velo-bold;
	}

	[data-locale='za'][data-theme='velo'] & {
		color: white;
	}
	[data-locale='uk'][data-theme='velo'] & {
		color: white;
	}

	[data-theme='vuse'] & {
		font-family: $font-family-trim;
		font-weight: $trim-black;
		font-size: 60px;
	}

	[data-theme='vuse'][data-locale='de'] & {
		font-size: 64px;
		line-height: 1.06;
		letter-spacing: 0.01em;

		margin-bottom: 30px;
	}

	[data-theme='glo'] & {
		font-family: $font-family-mont;
		font-weight: $mont-heavy;
	}

	.qrCodes & {
		[data-theme='vuse'] & {
			font-family: $font-family-gotham;
			font-weight: $gotham-black;
			font-size: 56px;
		}
	}
}
.strapline {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 24px;
	line-height: 1.5;
	width: 66%;
	margin: 0 auto;
	margin-top: 5px;

	white-space: pre-wrap;

	.qrCodes & {
		font-size: 32px;
		letter-spacing: 0;
		line-height: 1.25;
		margin-top: 10px;
	}

	[data-theme='velo'] [data-layout='pick'] & {
		font-size: 25px;
		font-family: $font-family-velo;
		font-weight: $velo-regular;
	}

	[data-locale='de'] & {
		font-size: 24px;
		letter-spacing: 0;
		line-height: 1.5;
	}
}
