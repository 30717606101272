@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrapper {
	position: relative;
	width: 100%;
	height: 100%;

	display: flex;
	flex-flow: row nowrap;

	z-index: 5;

	[data-locale='de'] & {
		padding-top: 100px;
	}
}
