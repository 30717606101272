@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.subscriptionPricesWrapper {
	max-width: 1200px;
	margin: 0 auto;

	display: grid;
	gap: 25px;
	grid-template-columns: repeat(var(--subscription-prices-row, 3), 1fr);
}

.boxWrapper {
	border-radius: 20px;
	overflow: hidden;
}

.header {
	color: white;
	background-color: #292a2b;
	padding: 20px 0;

	text-align: center;
	border-bottom: 5px solid #883885;
}

.mainBody {
	background-color: white;
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;

	padding: 20px;
}

.title {
	display: block;

	font-family: $font-family-trim;
	font-weight: $trim-black;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0;

	margin-bottom: 5px;
}

.periodicity {
	display: block;

	color: #b9b9b9;
	font-family: $font-family-gotham;
	font-weight: $gotham-book;

	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0;
}

.table {
	color: black;
	border: 2px solid #dfe0e1;
}

.tableHeader {
	padding: 18px 13px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.badge {
	min-width: 105px;
	border-radius: 8px;
	padding: 9px 28px;

	text-align: center;

	font-family: $font-family-gotham;
	font-weight: $gotham-black;
	font-size: 12px;
	letter-spacing: 0.08;
	line-height: 13px;

	&.bronze {
		background: linear-gradient(90deg, #e68a54 0%, #e48751 55%, #c7602c 100%);
	}
	&.silver {
		background: linear-gradient(90deg, #afc5d7 0%, #b1c7d7 55%, #8798b2 100%);
	}
	&.gold {
		background: linear-gradient(90deg, #f8b74e 0%, #fbb54a 55%, #f5963b 100%);
	}
}

.productAmount {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	font-size: 16px;
	letter-spacing: 0;
}

.tableBody {
	display: flex;
}

.itemColumn {
	width: 50%;
	padding: 18px 15px;

	text-align: center;

	border-top: 2px solid #dfe0e1;
	background: #eeeff0;

	&:nth-of-type(2) {
		.itemPrice .value {
			font-size: 18px;
		}
	}

	&:not(:first-of-type) {
		border-left: 2px solid #dfe0e1;
		background: white;
	}
}

.itemPrice {
	display: block;

	font-family: $font-family-gotham;
	font-weight: $gotham-black;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 18px;
}
.itemSubtitle {
	color: #5d5e5f;

	font-family: $font-family-gotham;
	font-weight: $gotham-medium;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 13px;
}
