@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';

.text {
	position: absolute;
	top: 109%;
	width: 580px;

	font-family: $font-family-gotham;
	font-weight: $gotham-light;
	font-size: 12px;

	line-height: 1.167;
	letter-spacing: 0em;
	color: black;

	[data-locale='uk'][data-theme='velo'] & {
		color: white;
	}

	[data-locale='za'][data-theme='velo'] & {
		color: white;
	}

	[data-locale='za'] & {
		top: 99%;
	}
}
