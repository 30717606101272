@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

$navbar-height: 53px;

:global(#bat_UI) {
	z-index: -1 !important;
}

:global(canvas) {
	z-index: -2 !important;
}

:global([data-theme='velo'][data-locale='za'] #bat_UI.velo .productTitle) {
	font-family: $font-family-velo;
	font-weight: $velo-regular;
}