@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.smallPrint {
	display: block;
	text-align: center;

	margin: 20px 150px 25px;

	font-size: 16px;
	letter-spacing: 0;
	line-height: 1.5;

	color: white;
}
