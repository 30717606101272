@import '../../../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../../../node_modules/bat-components/dist/styles/includes/functions';

.ctaAdWrapper {
	display: flex;
	flex-direction: column;

	font-family: $font-family-gotham;
	font-weight: $gotham-book;
	color: white;
	font-size: rescale(12px);
	letter-spacing: -0.022em;

	margin-bottom: 7.3px;

	[data-locale='za'] {
		width: auto;
		height: 100%;
	}

	span {
		[data-locale='us'] & {
			font-weight: $gotham-bold;
		}

		[data-locale='za'][data-theme='glo'] & {
			text-transform: none;
		}

		[data-locale='za'][data-theme='velo'] & {
			color: black;
		}

		[data-theme='velo'][data-locale='za'] & {
			color: white;
			letter-spacing: 0;
		}

		[data-theme='velo'][data-locale='uk'] & {
			color: white;
		}

		&.velo,
		&.lyft,
		&.epok {
			color: black;

			[data-locale='za'] & {
				color: white;
			}

			[data-locale='uk'] & {
				color: white;
			}
		}

		.divider {
			margin: 0 12px;
		}

		.strong {
			font-weight: $gotham-black;
		}

		[data-locale='us'] & {
			text-align: center;
			font-size: 12px;
			line-height: 15px;
			text-transform: uppercase;

			padding: 15px 0;
		}

		[data-locale='za'] & {
			text-align: center;
			font-size: 16px;
			line-height: 15px;
			text-transform: uppercase;

			padding: 5px 0;
		}

		[data-theme='velo'] & {
			font-size: 16px;
			line-height: 18px;
			font-family: $font-family-velo;
			font-weight: $velo-medium;
			padding: 10px 0px;
		}
	}
}

.ctaImage {
	width: 217px !important;
	height: auto;

	margin-top: 0;
	margin-bottom: auto;
}