@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.block {
	text-align: center;
	width: 50%;
}

.blockWrapper {
	padding-top: 76px;

	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 5;

	height: 100%;
}

[data-locale='us'] {
	.blockWrapper {
		padding-top: 0;
	}
}

.mainTitle {
	&.intro {
		color: white;
		margin: rescale(33px) 0 rescale(75px);

		text-align: center;
		text-transform: uppercase;

		font-size: rescale(40px);
		font-family: $font-family-trim;
		font-weight: $trim-bold;

		letter-spacing: 0em;
		line-height: 1;

		&.velo {
			font-family: $font-family-euclidFlex !important;
			font-weight: $euclidFlex-light !important;
		}
	}
}
